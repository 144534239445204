import img1 from '../images/fotoCompany/photo_12_2024-01-27_22-45-55.jpg';
import img2 from '../images/fotoCompany/photo_11_2024-01-27_22-45-55.jpg';
import img3 from '../images/fotoCompany/photo_10_2024-01-27_22-45-55.jpg';
import img4 from '../images/fotoCompany/photo_1_2024-01-27_22-45-55.jpg';
import img5 from '../images/fotoCompany/photo_2_2024-01-27_22-45-55.jpg';
import img6 from '../images/fotoCompany/photo_3_2024-01-27_22-45-55.jpg';
import img7 from '../images/fotoCompany/photo_4_2024-01-27_22-45-55.jpg';
import img8 from '../images/fotoCompany/photo_5_2024-01-27_22-45-55.jpg';
import img9 from '../images/fotoCompany/photo_6_2024-01-27_22-45-55.jpg';
import img10 from '../images/fotoCompany/photo_7_2024-01-27_22-45-55.jpg';
import img11 from '../images/fotoCompany/photo_8_2024-01-27_22-45-55.jpg';
import img12 from '../images/fotoCompany/photo_9_2024-01-27_22-45-55.jpg';

const mapCards = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
];
export default mapCards;
